/**
 * Parses a date string in the format 'yy-mm-dd' or 'yyyy-mm-dd' and returns a Date object.
 * @param dateString - The date string to parse.
 * @returns A Date object or null if the date is invalid.
 */
export const parseDate = (dateString?: string): Date | null => {
    if (!dateString) return null;
    const [yearRaw, month, day] = dateString.split('-').map(Number);
    let year = yearRaw; // Reassign `year` since it's being adjusted later

    if (year < 100) {
        year = 2000 + year; // Adjust for 'yy' format
    }

    if (year < 100) {
        year = 2000 + year; // Adjust for 'yy' format
    }

    // Verify the date's numeric validity
    if ([year, month, day].some(isNaN)) return null;

    return new Date(year, month - 1, day);
};

/**
 * Checks if a given date string represents a valid future date.
 * @param dateString - The date string to validate.
 * @returns A boolean indicating whether the date is in the future.
 */
export const isValidFutureDate = (dateString?: string): boolean => {
    const currentDate = new Date();
    const parsedDate = parseDate(dateString);

    return parsedDate !== null && parsedDate >= currentDate;
};

/**
 * Filters the irregular opening hours to include only those with valid future dates,
 * and takes into account the `hideUntil` (showFrom) property.
 * @param irregularOpeningHours - Array of irregular opening hours.
 * @returns Filtered array of irregular opening hours that are in the future and not hidden by `hideUntil`.
 */
export const getUpcomingIrregularHours = (irregularOpeningHours?: { date?: string; showFrom?: string }[]) => {
    if (!Array.isArray(irregularOpeningHours)) return [];

    return irregularOpeningHours.filter(({ date, showFrom }) => {
        const isFutureDate = isValidFutureDate(date);
        const parsedShowFrom = parseDate(showFrom);
        const currentDate = new Date();

        // If showFrom is not defined or is in the past, the entry should be shown if the date is in the future
        const shouldHide = parsedShowFrom ? parsedShowFrom > currentDate : false;

        return isFutureDate && !shouldHide;
    });
};
