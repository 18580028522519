import React, { useEffect, useRef } from 'react';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import CmsModules from '@sportson/core-web/libs/wordpress/content/CmsModules';
import { shallowEqual } from 'react-redux';
import styled from 'libs/styled';
import Text from 'components/Text';
import { TabBarLinks } from 'components/TabBarLinks';
import { inServer } from '@sportson/core-web/constants';

import { GOOGLE_MAP_STYLE } from 'config/googleMapStyle';
import Breadcrumbs from 'components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';
import Marker from 'assets/media/icons/Marker';
import { GOOGLE_MAPS_API_KEY } from 'config/constants';
import { StoreInformationPanel } from './StoreInformationPanel';

const StoreWrapper = styled('div')``;
const StoreHeader = styled('div')``;
const StoreHeaderTop = styled('div')``;
const MapArea = styled('div')`
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 1px;
        background-color: var(--color-neutrals-200);
    }
`;

const MapWrapper = styled('div')`
    width: 100%;
    height: 500px;
    background-color: var(--color-neutrals-400);
    overflow: hidden;
`;

const Store = () => {
    const { t } = useTranslation();
    const { id, permalink, title, children, siblings, ancestors, pageContent, storeContent, template } = useAppSelector(
        ({ page }) => page?.data,
        shallowEqual,
    );

    const encodedSvg = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(Marker)}`;
    const { stores } = useAppSelector(({ ecommerce }) => ecommerce?.stores, shallowEqual);

    let breadcrumbData = [{ permalink, title }];
    const basePermalink = permalink.replace(/(\/butik).*/, '$1');
    breadcrumbData = [{ permalink: basePermalink, title: `${t('store.stores')}` }, ...breadcrumbData];

    const storeWpId = ancestors.length ? ancestors[0].id.toString() : id.toString();
    const masterStoreInformation = stores.find((s) => s.id === storeWpId);
    const { norceStore, contact } = masterStoreInformation;
    let { openingHours, irregularOpeningHours, checkmarks, facebook, instagram } = masterStoreInformation;
    let { email, phone } = contact || {};
    const {
        openingHours: currentOpeningHours,
        irregularOpeningHours: currentIrregularOpeningHours,
        checkmarks: currentCheckmarks,
        contact: currentContact,
        facebook: currentFacebook,
        instagram: currentInstagram,
    } = storeContent || {};

    if (currentOpeningHours) {
        openingHours = currentOpeningHours;
    }
    if (currentIrregularOpeningHours) {
        irregularOpeningHours = currentIrregularOpeningHours;
    }
    if (currentCheckmarks) {
        checkmarks = currentCheckmarks;
    }
    if (currentFacebook) {
        facebook = currentFacebook;
    }
    if (currentInstagram) {
        instagram = currentInstagram;
    }
    if (currentContact?.email) {
        email = currentContact.email;
    }
    if (currentContact?.phone) {
        phone = currentContact.phone;
    }
    const customContact = { email, phone };
    const socialMedia = { facebook, instagram };

    const currentPage = { id, permalink, title };
    const storeNavigationMainPage = ancestors.length ? ancestors[0] : currentPage;
    const storeNavigationSubPages = siblings.length ? siblings : children;
    const sortedStoreNavigationSubPages = [...storeNavigationSubPages].sort((a, b) => a.sortOrder - b.sortOrder);
    let map;
    let infoWindow;
    const mapRef = useRef(null); // Create a ref for the map container

    const handlePinClick = (marker, info) => {
        map.panTo(marker.getPosition());
        infoWindow.setContent(`
            <div style="
                overflow: auto;
                cursor: default;
                clear: both;
                position: relative;
                border-width: 0px;
                border-radius: 0px;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 0;
            ">
                <p style="
                    font-family: 'FS Emeric', sans-serif;
                    font-size: 20px;
                    font-weight: 450;
                    line-height: 20px;
                    text-align: left;
                    margin: 0;
                ">
                    ${norceStore?.name || ''}
                </p>
                <p style="
                    font-family: 'FS Emeric', sans-serif;
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 20.8px;
                    text-align: left;
                    margin: 0;
                ">
                    ${norceStore?.address || ''}
                    <br/>
                    ${norceStore?.postalAddress || ''}
                    <br/>
                    <br/>
                    <a href="tel:${customContact?.phone || norceStore?.phone || ''}" style="color: inherit; text-decoration: none;">
                        ${customContact?.phone || norceStore?.phone || ''}
                    </a>
                    <br/>
                    <a href="mailto:${customContact?.email || norceStore?.email || ''}" style="color: inherit; text-decoration: none;">
                        ${customContact?.email || norceStore?.email || ''}
                    </a>
                </p>
            </div>
        `);

        infoWindow.open(map, marker);
    };

    const initMap = () => {
        if (mapRef.current) {
            // Check if map div exists
            const latitude = Number(norceStore.latitude);
            const longitude = Number(norceStore.longitude);

            map = new window.google.maps.Map(mapRef.current, {
                center: new window.google.maps.LatLng(latitude, longitude),
                zoom: 14,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                scrollwheel: false,
                draggable: true,
                gestureHandling: 'cooperative',
                styles: GOOGLE_MAP_STYLE,
            });

            infoWindow = new window.google.maps.InfoWindow();

            const markerInfo = {
                lat: latitude,
                lng: longitude,
                title,
                storeId: id,
                contact,
                postalAddress: norceStore.postalAddress,
            };

            const marker = new window.google.maps.Marker({
                position: { lat: markerInfo.lat, lng: markerInfo.lng },
                map,
                title: markerInfo.title,
                icon: {
                    url: encodedSvg,
                    scaledSize: new window.google.maps.Size(40, 40),
                    anchor: new window.google.maps.Point(10, 10),
                },
            });

            marker.addListener('click', () => {
                handlePinClick(marker, markerInfo);
            });
        }
    };

    const loadGoogleMapsScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initMap`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
                window.initMap();
            } else {
                console.error('Google Maps API failed to initialize.');
            }
        };
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    };

    useEffect(() => {
        // Only load the map when the template is 'store_map'
        if (template === 'store_map' && !inServer) {
            window.initMap = initMap;
            return loadGoogleMapsScript();
        }
    }, [template]); // Re-run the effect whenever the template changes

    return (
        <StoreWrapper>
            <Breadcrumbs
                gridColumn="standard"
                p={['16px 24px 16px 12px', null, null, null, null, '16px 40px']}
                breadcrumbs={breadcrumbData}
            />
            <StoreHeader>
                <StoreHeaderTop p={['16px 12px', null, null, null, '16px 40px 24px']}>
                    <Button to={basePermalink} variant="secondary">
                        <ArrowIcon direction="left" size="xsmall" /> {t('store_listing.store_list')}
                    </Button>
                    <Text as="h1" mt="24px" mb="24px" typography="Content/48_120_0_500">
                        {title}
                    </Text>
                    <TabBarLinks
                        mb="4px"
                        currentId={id}
                        navigationItems={[storeNavigationMainPage, ...sortedStoreNavigationSubPages]}
                    />
                </StoreHeaderTop>

                {template !== 'store_map' && (
                    <StoreInformationPanel
                        checkmarks={checkmarks}
                        openingHours={openingHours}
                        irregularOpeningHours={irregularOpeningHours || []}
                        isAccordion={storeContent?.isAccordion}
                        storeInformation={norceStore}
                        contact={customContact}
                        socialMedia={socialMedia}
                    />
                )}
            </StoreHeader>

            {template === 'store_map' && (
                <MapArea p={['12px', null, null, null, '40px']}>
                    <MapWrapper ref={mapRef} id="store-map" />
                </MapArea>
            )}
            {pageContent && <CmsModules data={pageContent} />}
        </StoreWrapper>
    );
};

export default Store;
