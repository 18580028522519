import React, { useState } from 'react';
import styled from 'libs/styled';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import { Check } from 'assets/media/icons/Check';
import { above } from '@sportson/core-web/utils/mediaqueries';
import Link from 'components/Link';
import { ChevronDown } from 'assets/media/icons/ChevronDown';
import CoreButton from '@grebban/react-core-components/Button';

import { getUpcomingIrregularHours } from '@sportson/core-web/utils/dateUtils';
import FacebookIcon from 'assets/media/icons/Facebook';
import InstagramIcon from 'assets/media/icons/Instagram';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 1px;
        background-color: var(--color-neutrals-200);
    }

    &::after {
        top: auto;
        bottom: 0;
    }

    ${above['desktop.sm']} {
        background-color: var(--color-base-white);
        flex-direction: row;
        gap: 0;
    }
`;

const InfoSection = styled('div')`
    width: 100%;

    ${above['desktop.sm']} {
        width: 25%;
    }

    &.irregular-opening-hours {
        border-radius: 4px;
        background-color: var(--color-neutrals-100);
        margin: 0 auto;
        padding: 12px;
        height: fit-content;

        ${above['desktop.sm']} {
            width: 25%;
        }
    }
`;

const Div = styled('div')``;

const SocialMedia = styled('div')`
    display: flex;
    gap: 12px;
`;

const Weekday = styled('div')`
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    width: 194px;
`;

const StyledLink = styled(Link)`
    > p {
        &::after {
            bottom: 2px;
        }
    }
`;

const ChevronContainer = styled(Div)<{ isExpanded: boolean }>`
    display: flex;
    align-items: center;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s ease;
`;

const Checkmarks = styled('ul')`
    list-style-type: none;
    padding-left: 0;
`;

const CheckMarkItem = styled('li')`
    list-style-type: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    gap: 4px;
`;
type OpeningHours = {
    hours?: string;
    weekday?: string;
    title?: string;
    date?: string;
};

type StoreCheckmark = {
    text: string;
};

type StoreInformation = {
    address: string;
    postalAddress: string;
    phone: string;
};

type Contact = {
    phone?: string;
    email?: string;
};

type SocialMedia = {
    facebook?: string;
    instagram?: string;
};

type StoreInformationPanelProps = {
    checkmarks?: StoreCheckmark[];
    openingHours?: OpeningHours[];
    irregularOpeningHours?: OpeningHours[];
    storeInformation: StoreInformation;
    contact?: Contact;
    isAccordion?: boolean;
    socialMedia?: SocialMedia;
};

export const StoreInformationPanel: React.FC<StoreInformationPanelProps> = ({
    checkmarks,
    openingHours,
    irregularOpeningHours,
    storeInformation,
    contact = {},
    isAccordion = true,
    socialMedia = {},
}) => {
    const { t } = useTranslation();
    const { address, postalAddress, phone } = storeInformation;
    const contactPhone = contact.phone || phone;
    const [isExpanded, setIsExpanded] = useState(!isAccordion);

    const defaultFacebookLink = 'https://www.facebook.com/sportson';
    const defaultInstagramLink = 'https://www.instagram/sportson';
    const facebookLink = socialMedia?.facebook || defaultFacebookLink;
    const instagramLink = socialMedia?.instagram || defaultInstagramLink;

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const upcomingIrregularHours = getUpcomingIrregularHours(irregularOpeningHours);
    return (
        <Wrapper p={['24px 12px 16px 12px', null, null, null, '24px 40px']}>
            <InfoSection>
                <Text as="h2" mb="16px" color="var(--color-neutrals-500)" typography="UI/12_100_0_450_uppercase">
                    {t('store.address')}
                </Text>
                <Text as="p" mb="24px" typography="Content/14_130_0_350">
                    {address}
                    <br />
                    {postalAddress}
                </Text>
                <Text as="h2" mb="16px" color="var(--color-neutrals-500)" typography="UI/12_100_0_450_uppercase">
                    {t('store.contact')}
                </Text>
                <Text as="p" typography="Content/14_130_0_350" pb="30px">
                    {t('store.phone')}: {contactPhone}
                    <br />
                    {contact?.email && (
                        <>
                            {t('store.email')}:{' '}
                            <StyledLink to={`mailto:${contact?.email}`} typography="Content/14_130_0_350">
                                {contact?.email}
                            </StyledLink>
                        </>
                    )}
                </Text>
                <SocialMedia pb="10px">
                    <Link to={facebookLink} noUnderline>
                        <FacebookIcon />
                    </Link>
                    <Link to={instagramLink} noUnderline>
                        <InstagramIcon />
                    </Link>
                </SocialMedia>
            </InfoSection>
            {checkmarks && checkmarks.length > 0 && (
                <InfoSection>
                    <Text as="h2" mb="16px" color="var(--color-neutrals-500)" typography="UI/12_100_0_450_uppercase">
                        {t('store.services')}
                    </Text>
                    <Checkmarks>
                        {checkmarks.map((item) => (
                            <CheckMarkItem key={item.text}>
                                <Div>
                                    <Check size="large" />
                                </Div>
                                <Text as="span" typography="Content/14_130_0_350">
                                    {item.text}
                                </Text>
                            </CheckMarkItem>
                        ))}
                    </Checkmarks>
                </InfoSection>
            )}
            {openingHours && openingHours.length > 0 && (
                <InfoSection>
                    <Text as="h2" mb="16px" color="var(--color-neutrals-500)" typography="UI/12_100_0_450_uppercase">
                        {t('store.opening_hours')}
                    </Text>
                    <Text as="p" mb="24px" typography="Content/14_130_0_350">
                        {openingHours.map((item) => (
                            <Weekday key={item.weekday}>
                                <span>{item.weekday}</span>
                                <span>{item.hours}</span>
                            </Weekday>
                        ))}
                    </Text>
                </InfoSection>
            )}
            {upcomingIrregularHours.length > 0 && (
                // Display all irregular opening hours if there are less than 3, if the irregular.accordion is true,
                // and if more than 3 irregular opening hours are NOT prohibited to be shown by the "irregularOpeningHours.showFrom" setting to be shown
                <InfoSection className="irregular-opening-hours" isExpandable={isExpanded}>
                    <Text as="h2" mb="16px" color="var(--color-neutrals-500)" typography="UI/12_100_0_450_uppercase">
                        {t('store.irregular_opening_hours')}
                    </Text>
                    <Text as="p" mb="10px" typography="Content/14_130_0_350">
                        {upcomingIrregularHours
                            .slice(0, isExpanded ? upcomingIrregularHours.length : 3)
                            .map((irregularDate: { title?: string; hours?: string; date?: string }) => {
                                const { title, hours, date } = irregularDate;
                                return (
                                    <Div display="flex" justifyContent="space-between">
                                        <span key={title}>
                                            {title} {`(${date})`}
                                        </span>
                                        <span key={hours}>{hours}</span>
                                    </Div>
                                );
                            })}
                    </Text>
                    {upcomingIrregularHours.length > 3 && (
                        <CoreButton
                            onClick={toggleExpand}
                            disabled={false}
                            name={null}
                            target={null}
                            type="button"
                            rel={null}
                            to={null}
                            value={null}
                        >
                            <Div display="flex">
                                <Text typography="Content/14_130_0_350" color="var(--color-neutrals-500)">
                                    {isExpanded
                                        ? t('store.hide_irregular_opening_hours')
                                        : t('store.show_irregular_opening_hours')}
                                </Text>
                                <ChevronContainer display="flex" isExpanded={isExpanded}>
                                    <ChevronDown color="var(--color-neutrals-500)" ml="4px" />
                                </ChevronContainer>
                            </Div>
                        </CoreButton>
                    )}
                </InfoSection>
            )}
        </Wrapper>
    );
};

export default StoreInformationPanel;
