import React, { useState, useEffect, useRef } from 'react';
import styled from 'libs/styled';
import Button from '@sportson/core-web/components/base/Button';
import Text from 'components/Text';
import { inServer } from '@sportson/core-web/constants';
import Link from '@sportson/core-web/components/Link';
import { above } from '@sportson/core-web/utils/mediaqueries';

const TabBarLinksWrapper = styled('div')`
    display: inline-flex;
    gap: 2px;
    padding: 2px;
    background-color: #e4e4e4;
    border-radius: 4px;
    position: relative;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    ${above['tablet.lg']} {
        width: auto;
    }
`;

const BarTabButton = styled(Link)`
    display: flex;
    padding: 8px 16px;
    border-radius: 2px;
    user-select: none;
    white-space: nowrap;
    z-index: 2;
`;

const ActiveTabBackround = styled('div')`
    position: absolute;
    top: 2px;
    height: 2px;
    background-color: var(--color-base-white);
    border-radius: 2px;
    z-index: 1;

    &.initialized {
        transition: all var(--transition-primary-fast);
    }
`;

type TabBarLinksProps = {
    currentId?: number;
    navigationItems: Array<{
        id: number;
        title: string;
        permalink: string;
        template: string;
    }>;
};

export const TabBarLinks: React.FC<TabBarLinksProps> = ({ currentId, navigationItems = [], ...rest }) => {
    const currentUrl = navigationItems.find((item) => item.id === currentId)?.permalink || navigationItems[0].permalink;
    const [selectedTab, setSelectedTab] = useState<string>(currentUrl);

    const [selectedTabPosition, setSelectedTabPosition] = useState<{
        left: number;
        width: number;
        height: number;
        initialized: boolean;
    }>({
        left: 0,
        width: 0,
        height: 0,
        initialized: false,
    });

    const tabBar = useRef<HTMLDivElement>(null);

    const handleTabClick = (el, value: string) => {
        if (el.currentTarget && !inServer) {
            const rect = el.currentTarget;
            setSelectedTabPosition({
                left: rect.offsetLeft,
                width: rect.offsetWidth,
                height: rect.offsetHeight,
                initialized: true,
            });
        }
        setSelectedTab(value);
    };

    useEffect(() => {
        if (tabBar.current && !inServer) {
            const rect = Array.from(tabBar.current.children).find((child) => {
                const link = child as HTMLAnchorElement;
                return link.getAttribute('href') === selectedTab;
            }) as HTMLElement;
            setSelectedTabPosition({
                left: rect.offsetLeft,
                width: rect.offsetWidth,
                height: rect.offsetHeight,
                initialized: false,
            });
        }
    }, []);

    const sortedNavigationItems = [...navigationItems].sort((a, b) =>
        a.template === 'store_map' ? 1 : b.template === 'store_map' ? -1 : 0,
    );

    return (
        <TabBarLinksWrapper ref={tabBar} {...rest}>
            {sortedNavigationItems.map((item) => (
                <BarTabButton
                    key={item.id}
                    style={{
                        backgroundColor: 'transparent',
                    }}
                    to={item.permalink}
                    onClick={(el) => handleTabClick(el, item.permalink)}
                >
                    <Text as="span" typography="UI/14_100_0_450">
                        {item.title}
                    </Text>
                </BarTabButton>
            ))}
            <ActiveTabBackround
                className={`slider ${selectedTabPosition.initialized ? 'initialized' : ''}`}
                style={{
                    left: selectedTabPosition.left,
                    width: selectedTabPosition.width,
                    height: selectedTabPosition.height,
                }}
            />
        </TabBarLinksWrapper>
    );
};
