import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import decodeHTMLEntities from '@sportson/core-web/utils/decodeHTMLEntities';
import { above } from '@sportson/core-web/utils/mediaqueries';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import Link from '@sportson/core-web/components/Link';
import styled from 'libs/styled';
import Text from 'components/Text';
import { gridMargin } from './wrappers/ContentGridWrapper';

const Wrapper = styled('div')``;

const Gradient = styled('div')`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 44px;
    pointer-events: none;
    z-index: 2;
`;

const GradientLeft = styled(Gradient)`
    left: 0;
    background: linear-gradient(to right, var(--color-base-white), rgba(255, 255, 255, 0));
`;

const GradientRight = styled(Gradient)`
    right: 0;
    background: linear-gradient(to left, var(--color-base-white), rgba(255, 255, 255, 0));
`;

const StyledBreadcrumbs = styled('div')`
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: auto hidden;
    z-index: 1;

    & > * {
        white-space: nowrap;

        :last-child {
            color: var(--color-neutrals-500);
        }
    }

    span {
        line-height: normal;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }

    ${above['desktop.sm']} {
        margin-left: calc(-1 * ${gridMargin.desktop});
        margin-right: calc(-1 * ${gridMargin.desktop});
        padding-left: calc(1 * ${gridMargin.desktop});
        padding-right: calc(1 * ${gridMargin.desktop});
    }
`;

const Breadcrumbs = ({ breadcrumbs = [], disableGradient = false, ...rest }) => {
    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    const { t } = useTranslation();

    if (!Array.isArray(breadcrumbs) || breadcrumbs.length === 0) {
        return null;
    }

    const breadCrumbsWithStartPage = [{ permalink: '/', title: t('breadcrumbs.home') }, ...breadcrumbs];

    return (
        <Wrapper {...rest}>
            {(!isMobile || !disableGradient) && <GradientLeft />}
            <StyledBreadcrumbs justifyContent="flex-start">
                {breadCrumbsWithStartPage.map(({ permalink, title }, index) => {
                    const key = `breadcrumb-${permalink}-${index}`;
                    return (
                        <>
                            {index > 0 && (
                                <Text key={`${key}-separator`} as="span" typography="UI/12_100_0_450_uppercase">
                                    |
                                </Text>
                            )}
                            {index + 1 === breadCrumbsWithStartPage.length ? (
                                <Fragment key={key}>
                                    <Text as="span" typography="UI/12_100_0_450_uppercase">
                                        {decodeHTMLEntities(title)}
                                    </Text>
                                </Fragment>
                            ) : (
                                <Fragment key={key}>
                                    <Link to={permalink}>
                                        <Text typography="UI/12_100_0_450_uppercase">{decodeHTMLEntities(title)}</Text>
                                    </Link>
                                </Fragment>
                            )}
                        </>
                    );
                })}
            </StyledBreadcrumbs>
            {(!isMobile || !disableGradient) && <GradientRight />}
        </Wrapper>
    );
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.array.isRequired,
    disableGradient: PropTypes.bool,
};

export default Breadcrumbs;
