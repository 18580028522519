const Marker = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.2 86.4">
  <g>
    <path fill="#FECB00" d="M76.2,38.1C76.2,17.1,59.1,0,38.1,0S0,17.1,0,38.1c0,17.9,12.4,33,29.1,37l8.5,11.3l8.2-11
      C63.1,71.8,76.2,56.5,76.2,38.1z"/>
    <path d="M44,15.1c0,0.4,0.4,0.5,0.7,0.5c0.4,0,0.8-0.1,0.9-0.1c0.3,0,0.5,0.1,0.5,0.5c0,0.9-1.7,1.2-1.7,2.1c0,0.3,0.1,0.4,0.4,0.4
      s0.5-0.3,0.8-0.3c0.3,0,0.3,0.3,0.3,0.4c0,0.4-0.8,0.5-0.8,0.8s0.1,0.5,0.5,0.5c0.4,0,0.8-0.4,1.2-0.4c0-0.1,0,0.1,0,0.3
      c0,0.3-1.4,0.9-4.6,3c-3.7,2.5-7,4.9-7,8.5c0,0.9,1.2,1.6,2,1.6c1.3,0,6.6-1.8,9.7-1.8c1.7,0,3.4,3,3.5,3.7
      c0.3,0.7,0.3,1.6,0.3,2.4c0,12.7-23.6,27.6-25.1,27.6c-1.2,0-1.3-1.1-1.3-1.7c0-1.4,1.6-1.4,1.6-2.2c0-0.3-0.1-0.4-0.1-0.7
      c0-0.3,0.3-0.4,0.3-0.8c0-0.1-0.4-0.3-0.5-0.3c-0.3,0-0.5,0.1-0.7,0.1c-0.3,0-0.4-0.1-0.4-0.4c0-1.3,1.8-2,1.8-2.4
      c0-0.1-0.1-0.3-0.3-0.3c-0.3,0-0.7,0.5-0.9,0.5c-0.1,0-0.7-0.1-0.7-0.8c0-1.4,2.6-2.5,7-5.5c1.1-0.7,9.2-7,9.2-10.1
      c0-0.5-0.3-0.8-1.3-0.8c-1.3,0-3.8,1.2-6,1.2c-4.5,0-6.6-4.2-6.6-8c0-10.8,8.5-17.2,16.8-21c1.1-0.1,1.4-0.3,2.1-0.3
      c0.9,0,1.2,0.5,1.2,1.3C46.7,13.9,44,14.2,44,15.1z"/>
  </g>
</svg>
`;

export default Marker;
