import Path from '@grebban/react-core-components/svg/Path';
import Svg from '@grebban/react-core-components/svg/Svg';

const sizes = {
    xsmall: {
        viewBox: '0 0 12 12',
        width: '12px',
        height: '12px',
        path: 'M3.5 6L5 7.5L8.5 4',
    },
    small: {
        viewBox: '0 0 17 16',
        width: '17px',
        height: '16px',
        path: 'M4.83301 8L6.83301 10L11.833 5',
    },
    large: {
        viewBox: '0 0 25 24',
        width: '25px',
        height: '24px',
        path: 'M7.16699 11.5L10.667 15L18.167 7.5',
    },
};

interface Props {
    color?: string;
    size?: 'xsmall' | 'small' | 'large';
    height?: string;
    width?: string;
    viewBox?: string;
}

export const Check = ({
    color = 'var(--color-base-black)',
    size = 'small',
    height,
    width,
    viewBox,
    ...rest
}: Props) => {
    const selectedSize = sizes[size] || sizes.small;
    return (
        <Svg
            width={width || selectedSize.width}
            height={height || selectedSize.height}
            viewBox={viewBox || selectedSize.viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <Path d={selectedSize.path} stroke={color} />
        </Svg>
    );
};
